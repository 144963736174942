body{
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #373857;
    text-align: left;
    background-color: #fff;
}

img{
    max-width: 100%;
}

a, .btn-link{
    text-decoration: none;
    color: #5556fd;
}

.btn-secondary{
    color:#fff !important;
}

.sidebar{
    box-shadow: 0 4px 12px 0 rgb(131 146 165 / 15%);
    border-right: 1px solid rgba(224, 225, 235, 0.85);
    width: 240px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 20;
    background-color: #fff;
}

.content{
    margin-left: 240px;
    position: relative;
    min-height: 100vh;
}

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid rgba(230, 231, 239, 0.85);
    transition: all 0.25s;
}

.sidebar-header{
    border-bottom: 1px solid rgba(230, 231, 239, 0.85);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-header img{
    max-width:100%;
    width: auto;
    height: 82px;
}

.header-left {
    padding: 15px;
    display: flex;
    align-items: center;
}

.header-right {
    padding: 15px;
    display: flex;
    align-items: center;
}

.toggle-sidebar .sidebar {
    left: -240px;
    border-right-width: 0;
    box-shadow: none;
}

.toggle-sidebar .content {
    margin-left: 0;
}

.content-header{
    padding: 9px  15px;
    display: flex;
    align-items: center;
}

.content-body{
    padding: 15px;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-sidebar{
    padding-left: 9px;
}

.nav-sidebar .nav-label {
    display: block;
}

.sidebar-body .content-label{
    padding:15px
}

.content-label {
    font-size: 10px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: .5px;
    text-transform: uppercase;
    color: rgba(55, 56, 87, 0.6);
    display: block;
}

.nav-sidebar .nav-item {
    display: block;
    position: relative;
}

.nav-sidebar .nav-link {
    position: relative;
    padding: 7px 8px;
    color: #373857;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    transition: all 0.25s;
    background-color:transparent;
    width: 100%;
    border: none;
}

.mr-2{
    margin-right: 15px;
}
.nav-sidebar .nav-item {
    display: block;
    position: relative;
}

.loaderspace{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:25px
}

.nav-sidebar {
    flex-direction: column;
}

.chat-panel {
    position: relative;
    height: 100%;
}

.chat-sidebar {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 12px 0 rgb(131 146 165 / 15%), 2px 2px 5px 0 rgb(60 70 83 / 4%);
    border: 1px solid rgba(224, 225, 235, 0.85);
    height: 75vh;
}

.chat-sidebar-header {
    justify-content: space-between;
    border-bottom: 1px solid rgba(230, 231, 239, 0.85);
    padding: 0 15px;
    width: 100%;
    justify-content: space-around;
}

.chat-sidebar-body {
    height: 65vh;
    padding: 0 15px;
}

.chat-sidebar-footer {
    padding: 0 15px;
    border-top: 1px solid rgba(230, 231, 239, 0.85);
}
.chat-sidebar-header, .chat-sidebar-footer {
    height: 50px;
    display: flex;
    align-items: center;
}

.chat-sidebar-footer .chat-loggeduser span {
    color: rgba(55, 56, 87, 0.6);
    font-weight: 400;
}

.chat-list{
    padding: 0;
    margin: 0;
    list-style: none;
}

.chat-item {
    display: flex;
    align-items: center;
    padding: 12px;
    position: relative;
}

.chat-list .avatar {
    flex-shrink: 0;
}

.avatar {
    position: relative;
    width: 38px;
    height: 38px;
}

.avatar.modifyprofile {
    width: 138px;
    height: 138px;
}

.avatar-initial {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #8392a5;
    color: #fff;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bgimgh {
    background-size: 100% auto;
}

.rounded-circle {
    border-radius: 50% !important;
}


.avatar {
    position: relative;
    width: 38px;
    height: 38px;
}

.chat-item-body {
    position: relative;
    margin-left: 12px;
    width: calc(100% - 50px);
}

.chat-item-body > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8125rem;
    line-height: 1.2;
    margin-bottom: 4px;
}

.chat-item-body > div:first-child span:last-child {
    font-size: 11px;
    color: #637382;
}

.bgimgh {
    background-size: cover;
    background-position: center center;
}

.chat-body-footer {
    height: 50px;
    padding: 0 15px;
    border-top: 1px solid rgba(230, 231, 239, 0.85);
    display: flex;
    align-items: center;
    background-color: #fff;
}

.chat-body {
    height: 100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid rgba(224, 225, 235, 0.85);
    box-shadow: 0 4px 12px 0 rgb(131 146 165 / 15%), 2px 2px 5px 0 rgb(60 70 83 / 4%);
    display: block;
}

.chat-body-header {
    height: 50px;
    border-bottom: 1px solid rgba(230, 231, 239, 0.85);
    display: flex;
    align-items: center;
    padding: 0 15px;
    width:100%
}

.chat-body-content {
    overflow-y:auto;
    position: relative;
    height:calc(100vh - 343px);
    background-color: #fff;
}

.chat-msg-list {
    padding: 15px;
    margin: 0;
    list-style: none;
}

.divider-text {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: rgba(55, 56, 87, 0.6);
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: .5px;
    margin: 15px 0;
}

.divider-text::before {
    margin-right: 10px;
}

.divider-text::after {
    margin-left: 10px;
}

.divider-text::before, .divider-text::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background-color: #e6e7ef;
}

.msg-item.reverse {
    flex-direction: row-reverse;
    text-align: right;
}

.msg-item {
    display: flex;
    margin-bottom: 25px;
}

.msg-item .avatar {
    flex-shrink: 0;
}

.msg-item.reverse .msg-body {
    margin-left: 0;
    margin-right: 10px;
}

.msg-body {
    flex: 1;
    margin-left: 15px;
}

.msg-user {
    font-size: 0.8125rem;
    margin-bottom: 15px;
}

.msg-user span {
    color: rgba(55, 56, 87, 0.6);
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
}

.msg-body p {
    font-size: 0.8125rem;
    color: #575d78;
    margin-bottom: 5px;
}

.chat-body-options a {
    color: rgba(55, 56, 87, 0.6);
}

.msg-body p:last-child {
    margin-bottom: 0;
}

.btn-info {
    color: #fff !important;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:hover {
    background-color: #138496;
    border-color: #117a8b;
}

.btn-outline-light {
    border-color: #c0ccda;
    color: rgba(55, 56, 87, 0.6);
}

.btn, .ui-datepicker-buttonpane button, .sp-container button {
    font-size: inherit;
    transition: all 0.2s;
}

.btn-outline-light {
    color: #f3f6f9;
    border-color: #f3f6f9;
}

.btn, .ui-datepicker-buttonpane button, .sp-container button {
    display: inline-block;
    font-weight: 400;
    color: #373857;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.437rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chat-body-footer .chat-body-options {
    margin-left: 0;
    margin-right: 15px;
}

.chat-body-options {
    margin-left: auto;
    display: flex;
    align-items: center;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
}

.btn-icon {
    padding-left: 10px;
    padding-right: 10px;
    min-width: 28px;
}

.btn, .ui-datepicker-buttonpane button, .sp-container button {
    font-size: inherit;
    transition: all 0.2s;
}

.btn, .ui-datepicker-buttonpane button, .sp-container button {
    display: inline-block;
    font-weight: 400;
    color: #373857;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    line-height: 1.5;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chat-body-footer .form-control {
    border-width: 0;
    padding: 0;
    font-size: 0.8125rem;
    color: #06072d;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.874rem + 2px);
    padding: 0.437rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #454f5b;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chat-body-footer .form-group {
    margin: 0;
    flex: 1;
}

.msg-item.reverse .msg-body p span {
    background-color: #5556fd;
    color: #fff;
}

.msg-body p span {
    max-width: 80%;
}
.msg-body p span {
    display: inline-block;
    background-color: #e6e7ef;
    padding: 7px 10px;
}

.card-profile-one .card-title {
    margin-bottom: 15px;
    color: #06072d;
}

.card-title {
    color: #06072d;
    line-height: 1;
}

.card-title {
    margin-bottom: 0.75rem;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}
h5, .h5 {
    font-size: 1.09375rem;
}

.text-muted {
    color: #637382 !important;
}
small, .small {
    font-size: 80%;
    font-weight: 400;
}

.card-profile-one .media-footer {
    display: flex;
    align-items: center;
}

.card-profile-one .media-footer h6 {
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    color: #06072d;
    margin-bottom: 0;
}

.card-profile-one .media-footer label {
    margin-bottom: 0;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .5px;
    color: #575d78;
}

.downbutton{
    padding:3px;
    border-radius:5px;
}

.card-people-two .card-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.bgimgh {
    background-size: cover;
}
.rounded-circle {
    border-radius: 50% !important;
}

.card-people-two .card-body {
    border: 1px solid rgba(230, 231, 239, 0.85);
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-width: 0;
    border-bottom-width: 0;
    padding-top: 50px;
    text-align: center;
}

.card-people-two .card-body p {
    color: rgba(55, 56, 87, 0.6);
    margin-bottom: 0;
    font-size: 0.8125rem;
}

.text-muted {
    color: #637382 !important;
}

.card-title {
    color: #06072d;
    line-height: 1;
}

.font-weight-light {
    font-weight: 300 !important;
}

.card-people-two .card-footer {
    padding: 0 15px 15px;
    padding-top: 0px;
    border: 1px solid rgba(230, 231, 239, 0.85);
    border-top-width: 1px;
    border-top-width: 0;
}

.btn-primary {
    color: #fff !important;
    background-color: #5556fd;
    border-color: #5556fd;
}

.btn-success {
    color: #fff !important;
}

.btn-danger {
    color: #fff !important;
    background-color: #dc3545;
    border-color: #dc3545;
}

.a-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.pos-absolute {
    position: absolute;
}

.card-people-two .card-header {
    border-width: 0;
    height: 70px;
    display: flex;
    justify-content: center;
    padding: 0;
    padding-top: 0px;
    padding-top: 20px;
    position: relative;
    background-color: #c0ccda;
}

.card-people-two .avatar {
    width: 84px;
    height: 84px;
    box-shadow: 0 0 0 2px #fff;
    border-radius: 100%;
}

.link {
    border: none;
    background: transparent;
    text-decoration: none;
    padding-top: 0;
    padding-bottom: 0;
}

.link-primary{
    color: #5556fd;
}

.badge {
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding: 3px 5px 4px;
    border-radius: 0;
    border: 1px solid transparent;
    display: inline-block;
    line-height: 1;
    text-align: left;
    white-space: normal;
    vertical-align: baseline;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-light{
    color:#777;
}

.badge-primary {
    color: #fff;
    background-color: #5556fd;
}

.card-profile-one .media-footer h6 {
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    color: #06072d;
    margin-bottom: 0;
}

.card-customer-score .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.text-primary {
    color: #5556fd !important;
}

.mg-b-5 {
    margin-bottom: 5px;
}

.card-profile-one .media-footer {
    display: flex;
    align-items: center;
}

.card-profile-one .media-footer h6 {
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    color: #06072d;
    margin-bottom: 0;
}

.card-profile-one .media-footer label {
    margin-bottom: 0;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .5px;
    color: #575d78;
}

.card-profile-one .media-footer > div + div {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid rgba(33, 43, 53, 0.08);
}

.card-customer-score .table-card tbody > tr td {
    font-size: 0.8125rem;
    padding: 6px 0;
}
.tx-medium {
    font-weight: 500;
}

.card-customer-score .table-card tbody > tr td:nth-child(3), .card-customer-score .table-card tbody > tr td:nth-child(4) {
    font-family: "Helvetica Neue", Helvetica, sans-serif;
}
.card-customer-score .table-card tbody > tr td:nth-child(3) {
    font-weight: 500;
}
.card-customer-score .table-card tbody > tr td {
    font-size: 0.8125rem;
    padding: 6px 0;
}
.text-right {
    text-align: right !important;
}

table {
    border-collapse: collapse;
}

.card-customer-score .table-card tbody > tr + tr {
    border-top: 1px dashed rgba(221, 222, 233, 0.85);
}

.bgimgh {
    background-size: cover;
}
.mr-1, .mx-1 {
    margin-right: 0.25rem !important;
}

.ml-2{
    margin-left: 0.25rem !important;
}
.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.d-inline-block {
    display: inline-block !important;
}

.nav-sidebar .nav-item.show .nav-sub {
    display: block;
}

.sideitems .nav-item.active{
    background-color: rgb(248, 249, 250);
}

.nav-sidebar .nav-sub {
    flex-direction: column;
    position: relative;
    display: none;
}

.nav-sidebar .nav-sub-link {
    padding: 6px 0 6px 34px;
    color: #575d78;
    font-size: 13px;
    display: block;
    white-space: nowrap;
}

.btn-outline-info:hover {
    color: #fff !important;
}

.nav-sidebar .nav-sub-link:hover, .nav-sidebar .nav-sub-link:focus {
    color: #5556fd;
}

.nav-sidebar .nav-link:hover, .nav-sidebar .nav-link:focus {
    background-color: rgba(85, 86, 253, 0.1);
}

.btn-block{
    display: block;
}

.nav-sub-link{
    border: none;
    background: transparent;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.rounded-circle {
    border-radius: 50% !important;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}

.card-contact-one .list-group-item {
    margin-right: 3px;
    padding: 12px 0;
    padding-top: 12px;
    box-shadow: none;
    display: flex;
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.list-group-item {
    padding: 10px 15px;
    border-color: rgba(230, 231, 239, 0.85);
    border-top-color: rgba(230, 231, 239, 0.85);
    border-right-color: rgba(230, 231, 239, 0.85);
    border-left-color: rgba(230, 231, 239, 0.85);
    box-shadow: 2px 2px 5px 0 rgba(60, 70, 83, 0.04);
}
.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top-color: rgba(0, 0, 0, 0.125);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgba(0, 0, 0, 0.125);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.125);
    border-left-color: rgba(0, 0, 0, 0.125);
    border-left-style: solid;
    border-left-width: 1px;
}

.card-contact-one .list-body {
    align-self: center;
    flex: 1;
    margin-left: 10px;
}

.card-contact-one .list-body h6 {
    color: #06072d;
    font-size: 13px;
    margin-bottom: 2px;
}

.badge {
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding: 3px 5px 4px;
    border-radius: 0;
    border: 1px solid transparent;
}
.badge-success {
    color: #fff;
    background-color: #28a745;
}

.card-contact-one .list-body p {
    font-size: 11px;
    font-weight: 300;
    font-family: "Oswald", sans-serif;
    margin-bottom: 0;
    color: #575d78;
}

.card-contact-one .list-group-item .nav {
    align-items: center;
    align-self: center;
}

.badge-info {
    color: #fff;
    background-color: #17a2b8;
}

.badge-secondary {
    color: #fff;
    background-color: #637382;
}

.badge-warning {
    color: #212b35;
    background-color: #ffc107;
}

.badge-danger {
    color: #fff;
    background-color: #dc3545;
}

.card-contact-one .list-group-item {
    padding: 12px;
    box-shadow: none;
    display: flex;
    border-bottom: 1px solid #ccc !important;
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.border-bottom{
    border-top:none !important;
    border-left:none !important;;
    border-right:none !important;;
    border-bottom:rgba(230, 231, 239, 0.85);
}

.header-right .dropdown-loggeduser .dropdown-menu {
    margin-right: -5px;
    min-width: 240px;
    border-radius: 0;
    padding: 0;
    border-color: rgba(224, 225, 235, 0.85);
    box-shadow: 0 4px 12px 0 rgba(131, 146, 165, 0.15), 2px 2px 5px 0 rgba(60, 70, 83, 0.04);
}

.mr-3{
    margin-right:10px;
}
.text-left{
    text-align: left;
}

.breadcrumb .breadcrumb-item a {
    color: rgba(55, 56, 87, 0.6);
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.sideitems .nav-link{
    color: #637382;
}

.sideitems .nav-link:hover{
    background-color:#f8f9fa
}
